import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Common/Header/Header';
import Homepages from './components/Home/Homepages';
import { useParams } from 'react-router-dom';
import More from './components/Home/News/more';
import Singlepages from './components/singlepages/Singlepages';
import Home from './components/Home/Main/homes/Home';
import Footer from './components/Common/footer/Footer';
import Vendorpage from './components/Vendordesc/Vendorpage';
import Login from './components/loginpage/login';
//import Mainpage from './components/mainpage/mainpage';
import Techie_index from './components/Techie-index/Techie_index';
import Watershow from './components/waterindex/watershow';
import Doctors from './components/Others/Doctors';
import Restaurant from './components/Others/Restaurant';
import Organizations from './components/Others/Organizations';
import Placetovisit from './components/Others/PlaceToVisit';
import Highlights from './components/Others/Highlights';
import Travel from './components/Others/Travel';

// importing Nadeem Component
import AfterLogin from './components/Nadeem/Components/AfterLogin';
//import AboutUs from './components/Nadeem/Components/About Us/AboutUs';
import Air from './components/Nadeem/Components/Indexes/Air index/Air';
import AllAi from './components/Nadeem/Components/All Ai Programs/AllAi';
import Stress from './components/Nadeem/Components/Indexes/Stress/Stress'
import Final_results from './components/Nadeem/Components/Final_reuslts_of_all_indexes/Final_results';
import Accidents from './components/Nadeem/Components/Indexes/Accidents/Accidents';
import Logins from './components/Nadeem/Components/Login/Logins'

import Main from './components/Nadeem/Components/Indexes/Nutrition/Main'
import Result from './components/Nadeem/Components/Indexes/Nutrition/Result'
import FoodsRequired from './components/Nadeem/Components/Indexes/Nutrition/FoodsReuired'
import Heros from './components/Others/Heros/Heros';

import SubPage from '../src/components/mainpage/SubPage';
import SmokingTest from './components/Nadeem/Components/Indexes/SmokingTest/SmokingTest';
import TechieIndexLatest from './components/TechieIndexLatest/TechieIndexLatest';
import TechieIndexSubPage from './components/TechieIndexLatest/TechieIndexSubPage';

import AllNews from './components/Home/News/AllNews';
import EventDetails from './components/Home/Events/EventDetails'
//pindex
import Diabetes from './components/pindex/Diabetes';
import InterviewDetails from './components/Home/Interview/InterviewDetails';
import SpotlightDetails from './components/Home/Main/flashnews/SpotlightDetails';
// import CIndex from './components/CIndex/CIndex';
// import CIndexDetails from './components/CIndex/CIndexDetails';
// import WaterIndexGoa from './components/CIndex/WaterIndex/WaterIndexGoa';
// import WorldPressFreedom from './components/CIndex/WorldPressFreedom/WorldPressFreedom';
import CityFromId from './components/Home/CityFromId/CityFromId';
import HeaderAttach from './components/Common/Header/HeaderAttach';
import Vendor from './components/Home/vendor/Vendor';
import Extra from './components/Home/Extra/Extra';
// import About from './components/Home/About/About';
import AllArticles from './components/Home/Articles/AllArticles';
import AllWhitePaper from './components/Home/WhitePaper/AllWhitePaper';
import AllLeaders from './components/Home/Interview/AllLeaders';
import AllSpotlights from './components/Home/Main/flashnews/AllSpotlights';
import AllEvents from './components/Home/Events/AllEvents';
import AllVendors from './components/Home/vendor/AllVendors';
import BusinessFromId from './components/Home/BusinessFromId/BusinessFromId';
import AllVendorCategories from './components/Home/VendorCategories/AllVendorCategories';
import ActivityPortal from './components/SMP/ActivityPortal/ActivityPortal';
import Actportal from './components/SMP/Harishna/Actportal';
import MainpageCategories from './components/mainpage/MainpageCategories';
import RainGaugeForm from './components/RainGauge/RainGaugeForm';
import Register from './components/loginpage/Register';
import PinPage from './components/mainpage/PinPage';
import AllUserContent from './components/Home/UserContent/AllUserContent';
import UserContentDetails from './components/Home/UserContent/UserContentDetails';
import MainpageHome from './components/mainpage/MainpageHome';
//import NewMainPage from './components/Home/NewMainPage/NewMainPage';
import LoginFromHtml from './components/loginpage/LoginFromHtml';
import AppHome from './components/UserApp/AppHome';
import Myhealth from './components/UserApp/Myhealth';
import EditProfile from './components/UserApp/UserProfile/EditProfile';
import BusinessHome from './components/UserApp/Business/BusinessHome';
import Addkfdocmnt from './components/UserApp/Business/Addkfdocmnt';
import Addvendor from './components/UserApp/Business/Addvendor';
import InterviewMainPage from './components/Home/InterviewHtml/InterviewMainPage';
import InterviewAllanMark from './components/Home/InterviewHtml/InterviewAllanMark';
import Interviewandrew_samsumg from './components/Home/InterviewHtml/Interviewandrew_samsumg';
import Interviewdarl_mcbride from './components/Home/InterviewHtml/Interviewdarl_mcbride';
import InterviewJackJia from './components/Home/InterviewHtml/InterviewJackJia';
import Interviewjim_steele from './components/Home/InterviewHtml/Interviewjim_steele';
import Interviewjim_vickers from './components/Home/InterviewHtml/Interviewjim_vickers';
import Interviewjoe_alsop from './components/Home/InterviewHtml/Interviewjoe_alsop';
import Interviewjohn_kopcke from './components/Home/InterviewHtml/Interviewjohn_kopcke';
import Interviewlief_pedersen from './components/Home/InterviewHtml/Interviewlief_pedersen';
import Interviewmark_ross from './components/Home/InterviewHtml/Interviewmark_ross';
import InterviewMarkCattini from './components/Home/InterviewHtml/InterviewMarkCattini';
import InterviewPatrick_Morrissey from './components/Home/InterviewHtml/InterviewPatrick_Morrissey';
import Interviewpaul_strassmann from './components/Home/InterviewHtml/Interviewpaul_strassmann';
import InterviewPaulGreenberg from './components/Home/InterviewHtml/InterviewPaulGreenberg';
import InterviewRobert_Stackowiak from './components/Home/InterviewHtml/InterviewRobert_Stackowiak';
import Interviewsteve_zimba from './components/Home/InterviewHtml/Interviewsteve_zimba';
import Interviewtechie_speak_David from './components/Home/InterviewHtml/Interviewtechie_speak_David';
import Interviewtechie_speak_Kumar from './components/Home/InterviewHtml/Interviewtechie_speak_Kumar';
import Interviewtechie_speak_SAJID_PATEL from './components/Home/InterviewHtml/Interviewtechie_speak_SAJID_PATEL';
import Interviewtechie_speakYunusDocrat from './components/Home/InterviewHtml/Interviewtechie_speakYunusDocrat';
import InterviewWilliam_Seidman from './components/Home/InterviewHtml/InterviewWilliam_Seidman';
import EditAccountProfile from './components/UserApp/AccountUserProfile/EditAccountProfile';
import LoginFooter from './components/loginpage/LoginFooter';
import LoginHeader from './components/loginpage/LoginHeader';
import CityFromName from './components/Home/CityFromName/CityFromName';
import StartupFromName from './components/Home/CityFromName/StartupFromName';
import IjobsFromName from './components/Home/CityFromName/IjobsFromName';
import IcampusFromName from './components/Home/CityFromName/IcampusFromName';
import HealthSurvey from './components/UserApp/HealthSurvey/health-survey';
// import Upload from './components/SMP/ImageUpload/UplloadImage/Upload';
import Upload from './components/SMP/ImageUpload/Upload';
import Redirect from './components/SMP/ImageUpload/Redirect';
import ApproveStory from './components/SMP/ImageUpload/ApproveStory';
import { Profile, ManagementT, Careers, ContactUs, Milestones, OurClients, Location } from './components/Home/About/About';
import AddLeaders from './components/UserApp/LeadersProfile/AddLeaders';
import TestQM from './components/UserApp/TestQA/TestQM';
import TestQuestions from './components/UserApp/TestQA/TestQuestions';
import TestAnswerBox from './components/UserApp/TestQA/TestAnswerBox';
import AllHealth from './components/Home/Health/AllHealth';

//import OverviewMB from './components/Home/AboutUs_MyBlocks/OverviewMB';
//import AboutusMBTechnology from './components/Home/AboutUs_MyBlocks/AboutusMBTechnology';
//import AboutusMBInternational from './components/Home/AboutUs_MyBlocks/AboutusMBInternational';
//import AboutusMBContactus from './components/Home/AboutUs_MyBlocks/AboutusMBContactus';
import ITServicesFromName from './components/Home/CityFromName/ITServicesFromName';
import HospitalList from './components/Home/vendor/Hospitals/HospitalList';
import HospitalDetail from './components/Home/vendor/Hospitals/HospitalDetail';
import Point from './components/UserApp/Points/point';
import HotelList from './components/Home/vendor/Hotels/HotelList';
import HotelDetail from './components/Home/vendor/Hotels/HotelDetail';
import SpotlightDetailsPage from './components/Home/Main/flashnews/SpotlightDetails/SpotlightDetailsPage';
import InterviewSpotlight from './components/Home/InterviewSpotlight/InterviewSpotlight';
import ForgotPasswordEmail from './components/loginpage/ForgetPassword/ForgotPasswordEmail';

import PasswordResetPage from './components/loginpage/ForgetPassword/PasswordResetPage';

import BusinessUserHome from './components/BusinessUser/BusinessUserHome';
import AdminUserHome from './components/AdminUser/AdminUserHome';


import BusinessUserList from './components/BusinessUser/BusinessUserList';
import TechieIndexLogin from './components/LoginPageTechieIndex/TechieIndexLogin';
import TechieIndexPage from './components/TechieIndexUser/TechieIndexPage';
import IJobsPage from './components/TechieIndexUser/IJobsPage';
import ICampusPage from './components/TechieIndexUser/ICampusPage';
import ManagerReview from './components/BusinessUser/EmployeeReport/ManagerReview';
import ReportPage from './components/BusinessUser/EmployeeReport/ReportPage';
import ImageList from './components/Home/UserContent/ImageList';


import Ranking from './components/Home/UserContent/Ranking';
import Details from './components/Home/UserContent/Details';
import UsaVC from './components/Home/UserContent/UsaVC';
import H1b from './components/Home/UserContent/H1b';
import CanadaUniversity from './components/Home/UserContent/CanadaUniversity';


import BusinessUserApprove from './components/BusinessUser/BusinessUserApprove';
import BusinessUserActivate from './components/BusinessUser/BusinessUserActivate';
import BulkEmailHome from './components/BusinessUser/BulkEmail/BulkEmailHome';
import AddMailingEmail from './components/BusinessUser/BulkEmail/AddMailingEmail';
import AddSendingEmails from './components/BusinessUser/BulkEmail/AddSendingEmails';
import TechieIndexRegister from './components/LoginPageTechieIndex/TechieIndexRegister';
import TechieIndexActivate from './components/LoginPageTechieIndex/TechieIndexActivate';
import FacebookSchedule from './components/SMP/SmpScheduling/FacebookSchedule';
import UploadMultipleImages from './components/SMP/SmpScheduling/UploadMultipleImages';
import ViewMultipleImages from './components/SMP/SmpScheduling/ViewMultipleImages';
import ScheduleMainPage from './components/SMP/SmpScheduling/ScheduleMainPage';
import SmpHome from './components/SMP/SmpHome';
import TxForgotPasswordEmail from './components/LoginPageTechieIndex/ForgetPassword/TxForgotPasswordEmail';
import TxPasswordResetPage from './components/LoginPageTechieIndex/ForgetPassword/TxPasswordResetPage';


// import Healthbook from './components/AdminUser/HealthBook/Healthbook';
import Leaders from './components/AdminUser/LeadersADD/Leaders';

import PageLogLocation from './components/PageLog/PageLogLocation';
import BasicAppLogin from './components/loginpage/MobileAppLoginPage/BasicAppLogin';
import BulkEmailHideMail from './components/BusinessUser/BulkEmail/BulkEmailHideMail';
import PageLogDisplay from './components/PageLog/PageLogDisplay';
import PageDisplayCount from './components/PageLog/PageDisplayCount';

import HosptialAdd from './components/AdminUser/HospitalAdding/HospitalAdd';
// import AboutusMBModels from './components/Home/AboutUs_MyBlocks/AboutusMBModels';
// import AboutusMBPlatform from './components/Home/AboutUs_MyBlocks/AboutusMBPlatform';
// import AboutusMBEcosystem from './components/Home/AboutUs_MyBlocks/AboutusMBEcosystem';
import PortalProbs from './components/CIndex/CindexPortalProblems/PortalProbs';
import FacebookOtp from './components/SMP/Facebook_Temp/FacebookOtp';
import MobileNews from './components/Home/NewsForMobile/MobileNews';
import SinglepagesMobile from './components/Home/NewsForMobile/SinglepagesMobile';
import FacebookContentScrapperHome from './components/SMP/FacebookContentScrapper/FacebookContentScrapperHome';
import FacebookPagePostScrapper from './components/SMP/FacebookContentScrapper/FacebookPagePostScrapper';
import HbReleases from './components/UserApp/Healthbook/HbReleases';
import FacebookUrlGoogleScrapper from './components/SMP/FacebookContentScrapper/FacebookUrlGoogleScrapper';
import DisplayAnalytics from './components/SMP/FacebookContentScrapper/DisplayAnalytics';
// import SocialMainPage from './components/SMP/ImageUpload/SocialAccounts/SocialMainPage';
// import SocialRedirectPage from './components/SMP/ImageUpload/SocialAccounts/SocialRedirectPage';
import TrendingPosts from './components/SMP/FacebookPostDisplay/TrendingPosts';
import FamilyGroupHome from './components/BusinessUser/AGIApp/FamilyGroup/FamilyGroupHome';
import FamilyGroupDetails from './components/BusinessUser/AGIApp/FamilyGroup/FamilyGroupDetails';
import MainAboutUs from './components/Home/AboutUs/AboutUs_MyBlocks/MainAboutUs';
import TopVolunteers from './components/UserApp/Points/TopVolunteers';
import HealthBookMobile from './components/UserApp/Healthbook/HealthBookMobile';
import ResumeUpload from './components/Careers/ResumeUpload';
import AboutUsTechie from './components/AboutUsTechie/AboutUsTechie'
import Trends from './components/SMP/Trends/Trends';
import FbEngagementsList from './components/SMP/Trends/FbEngagementsList';
import BestPosts from './components/SMP/Trends/BestPosts';
import TrendsShowFollowerCount from './components/SMP/Trends/TrendsShowFollowerCount';
import TrendsHome from './components/SMP/Trends/TrendsHome';

import GapAnalysis from './components/Careers/GapAnalysis';
import Roadmap from './components/Careers/Roadmap';
import CareerBook from './components/Careers/Careerbook';
import EditAccountProfileBusiness from './components/BusinessUser/AccountUserProfile/EditAccountProfileBusiness';
import KfDocmntApproval from './components/AdminUser/AdminApproval/KfDocmntApproval';
import KfDocmntApprovalDetails from './components/AdminUser/AdminApproval/KfDocmntApprovalDetails';

import CiIndexPindexMainPage from './components/AdminUser/AiIndex/CiIndexPindexMainPage';

import Lab from './components/UserApp/Lab/Lab';
import InstagramTutorial from './components/SMP/ImageUpload/Instagram_Posting/InstagramTutorial';
import UploadTest from './components/SMP/ImageUpload/UploadTest';
import CindexPython from './components/UserApp/CindexPython/CindexPython';
import WaterMarkAddingHome from './components/SMP/WaterMarkAdding/WaterMarkAddingHome';
import WaterMarkForm from './components/SMP/WaterMarkAdding/WaterMarkForm';

function DefaultLayout({ children }) {
  return (
    <>
      <Header />
      <HeaderAttach />
      {children}
      <Footer />
    </>
  );
}


function loginLayout({ children }) {
  return (
    <>
      <LoginHeader />
      {children}
      <LoginFooter />
    </>
  );
}

function App() {

  return (
    <>
      <Router>

        <Routes>
          {/* <Route exact path='/' element={<NewMainPage/>}/> */}
          <Route exact path='/' element={<Login />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/home' element={<DefaultLayout><MainpageHome /></DefaultLayout>} />
          <Route exact path='/' element={<DefaultLayout><MainpageCategories /></DefaultLayout>} />
          <Route exact path='/more' element={<DefaultLayout><More /></DefaultLayout>} />
          <Route exact path='/techieindex' element={<DefaultLayout><Techie_index /></DefaultLayout>} />
          <Route exact path='/singlepages' element={<DefaultLayout><Singlepages /></DefaultLayout>} />
          <Route exact path='/vendorpage' element={<DefaultLayout><Vendorpage /></DefaultLayout>} />
          <Route exact path='/waterindex' element={<DefaultLayout><Watershow /></DefaultLayout>} />
          <Route exact path='/doctors' element={<DefaultLayout><Doctors /></DefaultLayout>} />
          <Route exact path='/restaurant' element={<DefaultLayout><Restaurant /></DefaultLayout>} />
          <Route exact path='/organizations' element={<DefaultLayout><Organizations /></DefaultLayout>} />
          <Route exact path='/Placetovisit' element={<DefaultLayout><Placetovisit /></DefaultLayout>} />
          <Route exact path='/highlights' element={<DefaultLayout><Highlights /></DefaultLayout>} />
          <Route exact path='/travel' element={<DefaultLayout><Travel /></DefaultLayout>} />
          <Route exact path='/heros' element={<DefaultLayout><Heros /></DefaultLayout>} />
          {/* Nadeem */}
          <Route path='/afterLogin' element={<DefaultLayout><AfterLogin /></DefaultLayout>} />
          {/* <Route path='/aboutUs' element={<DefaultLayout><AboutUs /></DefaultLayout>} /> */}
          <Route path="/air" element={<DefaultLayout><Air /></DefaultLayout>} />
          <Route path="/allIndexResults" element={<DefaultLayout><AllAi /></DefaultLayout>} />
          <Route path="/stress" element={<DefaultLayout><Stress /></DefaultLayout>} />
          <Route path="/final_results" element={<DefaultLayout><Final_results /></DefaultLayout>} />
          <Route path="/accident" element={<DefaultLayout><Accidents /></DefaultLayout>} />
          <Route path="/logins" element={<DefaultLayout><Logins /></DefaultLayout>} />
          <Route path="/main" element={<DefaultLayout><Main /></DefaultLayout>} />
          <Route path="/result" element={<DefaultLayout><Result /></DefaultLayout>} />
          <Route path="/foodsrequired" element={<DefaultLayout><FoodsRequired /></DefaultLayout>} />
          <Route path="/Cities" element={<DefaultLayout><SubPage /></DefaultLayout>} />
          <Route path="/SmokingTest" element={<DefaultLayout><SmokingTest /></DefaultLayout>} />
          <Route path="/techieindexlatest" element={<DefaultLayout><TechieIndexLatest /></DefaultLayout>} />
          <Route path="/TechieIndexSubPage" element={<DefaultLayout><TechieIndexSubPage /></DefaultLayout>} />
          {/* pindex */}
          <Route path="/Diabetes" element={<DefaultLayout><Diabetes /></DefaultLayout>} />
          {/* news */}
          <Route path="/AllNews" element={<DefaultLayout><AllNews /></DefaultLayout>} />
          <Route path="/AllArticles" element={<DefaultLayout><AllArticles /></DefaultLayout>} />
          <Route path="/AllWhitepaper" element={<DefaultLayout><AllWhitePaper /></DefaultLayout>} />
          <Route path="/EventDetails" element={<DefaultLayout><EventDetails /></DefaultLayout>} />
          <Route path="/InterviewDetails" element={<DefaultLayout><InterviewDetails /></DefaultLayout>} />
          <Route path="/SpotlightDetails" element={<DefaultLayout><SpotlightDetails /></DefaultLayout>} />
          {/* <Route path="/CIndex" element={<DefaultLayout><CIndex /></DefaultLayout>} />
          <Route path="/CIndexDetails" element={<DefaultLayout><CIndexDetails /></DefaultLayout>} />
          <Route path="/WaterIndexGoa" element={<DefaultLayout><WaterIndexGoa /></DefaultLayout>} /> */}
          <Route path="/CityFromId/:id" element={<DefaultLayout><CityFromId /></DefaultLayout>} />
          <Route path="/Business" element={<DefaultLayout><Vendor /></DefaultLayout>} />
          <Route path="/Extra" element={<DefaultLayout><Extra /></DefaultLayout>} />
          {/* <Route path="/About" element={<DefaultLayout><About /></DefaultLayout>} /> */}
          <Route path="/AllLeaders" element={<DefaultLayout><AllLeaders /></DefaultLayout>} />
          <Route path="/AllSpotlights" element={<DefaultLayout><AllSpotlights /></DefaultLayout>} />
          <Route path="/AllEvents" element={<DefaultLayout><AllEvents /></DefaultLayout>} />
          <Route path="/AllVendors" element={<DefaultLayout><AllVendors /></DefaultLayout>} />
          <Route path="/business/:id" element={<DefaultLayout><BusinessFromId /></DefaultLayout>} />
          <Route path="/Business" element={<DefaultLayout><Vendor /></DefaultLayout>} />
          <Route path="/AllVendorCategories" element={<DefaultLayout><AllVendorCategories /></DefaultLayout>} />
          <Route path="/ActivityPortal" element={<DefaultLayout><ActivityPortal /></DefaultLayout>} />
          <Route path="/actportal" element={<DefaultLayout><Actportal /></DefaultLayout>} />
          <Route path="/raingaugeform" element={<DefaultLayout><RainGaugeForm /></DefaultLayout>} />
          {/* <Route path="/Register" element={<DefaultLayout><Register /></DefaultLayout>} /> */}
          <Route path="/Register" element={<Register />} />

          <Route path="/PinPage" element={<DefaultLayout><PinPage /></DefaultLayout>} />
          <Route path="/AllUserContent" element={<DefaultLayout><AllUserContent /></DefaultLayout>} />
          <Route path="/UserContentDetails" element={<DefaultLayout><UserContentDetails /></DefaultLayout>} />
          <Route path="/loginfromhtml" element={<DefaultLayout><LoginFromHtml /></DefaultLayout>} />
          <Route path="/AppHome" element={<DefaultLayout><AppHome /></DefaultLayout>} />
          <Route path="/Myhealth" element={<DefaultLayout><Myhealth /></DefaultLayout>} />
          <Route path="/EditProfile" element={<DefaultLayout><EditProfile /></DefaultLayout>} />
          <Route path="/BusinessHome" element={<DefaultLayout><BusinessHome /></DefaultLayout>} />

          <Route path="/Addkfdocmnt" element={<DefaultLayout><Addkfdocmnt /></DefaultLayout>} />
          <Route path="/Addkfdocmnt1" element={<DefaultLayout><Addkfdocmnt /></DefaultLayout>} />

          <Route path="/Addvendor" element={<DefaultLayout><Addvendor /></DefaultLayout>} />
          <Route path="/Addvendor1" element={<DefaultLayout><Addvendor /></DefaultLayout>} />

          <Route path="/InterviewMainPage" element={<DefaultLayout><InterviewMainPage /></DefaultLayout>} />
          <Route path="/InterviewAllanMark" element={<DefaultLayout><InterviewAllanMark /></DefaultLayout>} />
          <Route path="/Interviewandrew_samsumg" element={<DefaultLayout><Interviewandrew_samsumg /></DefaultLayout>} />
          <Route path="/Interviewdarl_mcbride" element={<DefaultLayout><Interviewdarl_mcbride /></DefaultLayout>} />
          <Route path="/InterviewJackJia" element={<DefaultLayout><InterviewJackJia /></DefaultLayout>} />
          <Route path="/Interviewjim_steele" element={<DefaultLayout><Interviewjim_steele /></DefaultLayout>} />
          <Route path="/Interviewjim_vickers" element={<DefaultLayout><Interviewjim_vickers /></DefaultLayout>} />
          <Route path="/Interviewjoe_alsop" element={<DefaultLayout><Interviewjoe_alsop /></DefaultLayout>} />
          <Route path="/Interviewjohn_kopcke" element={<DefaultLayout><Interviewjohn_kopcke /></DefaultLayout>} />
          <Route path="/Interviewlief_pedersen" element={<DefaultLayout><Interviewlief_pedersen /></DefaultLayout>} />
          <Route path="/Interviewmark_ross" element={<DefaultLayout><Interviewmark_ross /></DefaultLayout>} />
          <Route path="/InterviewMarkCattini" element={<DefaultLayout><InterviewMarkCattini /></DefaultLayout>} />
          <Route path="/InterviewPatrick_Morrissey" element={<DefaultLayout><InterviewPatrick_Morrissey /></DefaultLayout>} />
          <Route path="/Interviewpaul_strassmann" element={<DefaultLayout><Interviewpaul_strassmann /></DefaultLayout>} />
          <Route path="/InterviewPaulGreenberg" element={<DefaultLayout><InterviewPaulGreenberg /></DefaultLayout>} />
          <Route path="/InterviewRobert_Stackowiak" element={<DefaultLayout><InterviewRobert_Stackowiak /></DefaultLayout>} />
          <Route path="/Interviewsteve_zimba" element={<DefaultLayout><Interviewsteve_zimba /></DefaultLayout>} />
          <Route path="/Interviewtechie_speak_David" element={<DefaultLayout><Interviewtechie_speak_David /></DefaultLayout>} />
          <Route path="/Interviewtechie_speak_Kumar" element={<DefaultLayout><Interviewtechie_speak_Kumar /></DefaultLayout>} />
          <Route path="/Interviewtechie_speak_SAJID_PATEL" element={<DefaultLayout><Interviewtechie_speak_SAJID_PATEL /></DefaultLayout>} />
          <Route path="/Interviewtechie_speakYunusDocrat" element={<DefaultLayout><Interviewtechie_speakYunusDocrat /></DefaultLayout>} />
          <Route path="/InterviewWilliam_Seidman" element={<DefaultLayout><InterviewWilliam_Seidman /></DefaultLayout>} />
          <Route path="/EditAccountProfile" element={<DefaultLayout><EditAccountProfile /></DefaultLayout>} />
          <Route path="/india/:city" element={<DefaultLayout><CityFromName /></DefaultLayout>} />
          <Route path="/portal/:city" element={<DefaultLayout><CityFromName /></DefaultLayout>} />
          <Route path="/:country/startups" element={<DefaultLayout><StartupFromName /></DefaultLayout>} />
          <Route path="/:country/ijobs" element={<DefaultLayout><IjobsFromName /></DefaultLayout>} />
          <Route path="/:country/icampus" element={<DefaultLayout><IcampusFromName /></DefaultLayout>} />

          <Route path='/imageupload' element={<DefaultLayout><Upload /></DefaultLayout>} />
          <Route path='/redirect' element={<DefaultLayout><Redirect /></DefaultLayout>} />
          <Route path='/approvestory' element={<DefaultLayout><ApproveStory /></DefaultLayout>} />

          <Route path="/healthsurvey" element={<DefaultLayout><HealthSurvey /></DefaultLayout>} />
          {/* <Route exact path="/profile" element={<DefaultLayout><Profile /></DefaultLayout>} />
          <Route path="/managementteam" element={<DefaultLayout><ManagementT /></DefaultLayout>} />
          <Route path="/careers" element={<DefaultLayout><Careers /></DefaultLayout>} />
          <Route path="/contactus" element={<DefaultLayout><ContactUs /></DefaultLayout>} />
          <Route path="/milestones" element={<DefaultLayout><Milestones /></DefaultLayout>} />
          <Route path="/ourclients" element={<DefaultLayout><OurClients /></DefaultLayout>} /> 
          <Route path="/location" element={<DefaultLayout><Location /></DefaultLayout>} />*/}
          <Route path="/addmyinfo" element={<DefaultLayout><AddLeaders /></DefaultLayout>} />
          <Route path="/testqmmain" element={<DefaultLayout><TestQM /></DefaultLayout>} />
          <Route path="/qmquestion/:id" element={<DefaultLayout><TestQuestions /></DefaultLayout>} />
          <Route path="/answerbox/:topicId/:questionId" element={<DefaultLayout><TestAnswerBox /></DefaultLayout>} />

          <Route path="/AllHealth" element={<DefaultLayout><AllHealth /></DefaultLayout>} />
          <Route path="/Aboutus" element={<DefaultLayout><MainAboutUs /></DefaultLayout>} />
          <Route path="/AboutUsTechie" element={<DefaultLayout><AboutUsTechie /></DefaultLayout>} />
          {/* <Route path="/Aboutusmbtechnology" element={<DefaultLayout><AboutusMBTechnology /></DefaultLayout>} />
          <Route path="/Aboutusmbinternational" element={<DefaultLayout><AboutusMBInternational /></DefaultLayout>} />
          <Route path="/Aboutusmbcontactus" element={<DefaultLayout><AboutusMBContactus /></DefaultLayout>} /> */}
          <Route path="/its" element={<DefaultLayout><ITServicesFromName /></DefaultLayout>} />

          <Route path="/hospitallist" element={<DefaultLayout><HospitalList /></DefaultLayout>} />
          <Route path="/HospitalDetail/:id" element={<DefaultLayout><HospitalDetail /></DefaultLayout>} />

          <Route path="/Point" element={<DefaultLayout><Point /></DefaultLayout>} />

          <Route path="/hotellist" element={<DefaultLayout><HotelList /></DefaultLayout>} />
          <Route path="/HotelDetail/:id" element={<DefaultLayout><HotelDetail /></DefaultLayout>} />

          <Route path="/spotlightdetailspage" element={<DefaultLayout>< SpotlightDetailsPage /></DefaultLayout>} />

          <Route path="/interviewspotlight" element={<DefaultLayout>< InterviewSpotlight /></DefaultLayout>} />
        

          <Route path="/forgotpasswordemail" element={< ForgotPasswordEmail />} />
          <Route path="/passwordresetpage" element={< PasswordResetPage />} />

          <Route path="/adminuserhome" element={<DefaultLayout>< AdminUserHome /></DefaultLayout>} />

          <Route path="/businessuserhome" element={<DefaultLayout><  BusinessUserHome /></DefaultLayout>} />
          <Route path="/businessuserlist" element={<DefaultLayout><  BusinessUserList /></DefaultLayout>} />

          <Route path="/tlogin" element={<DefaultLayout><  TechieIndexLogin /></DefaultLayout>} />
          <Route path="/techieindexregister" element={<DefaultLayout><  TechieIndexRegister /></DefaultLayout>} />
          <Route path="/techieindexactivate" element={<DefaultLayout><  TechieIndexActivate /></DefaultLayout>} />
          
          
          <Route path="/techieindexpage" element={<DefaultLayout><  TechieIndexPage /></DefaultLayout>} />
          <Route path="/ijobspage" element={<DefaultLayout><  IJobsPage /></DefaultLayout>} />
          <Route path="/icampuspage" element={<DefaultLayout><  ICampusPage /></DefaultLayout>} />
          <Route path="/projects" element={<DefaultLayout><ReportPage /> </DefaultLayout>} />
          <Route path="/employee-report" element={<DefaultLayout><ManagerReview /></DefaultLayout>} />

          <Route path="/imagelist" element={<DefaultLayout><ImageList /></DefaultLayout>} />

          <Route path="/Ranking" element={<DefaultLayout><Ranking /></DefaultLayout>} />
          <Route path="/UsaVC" element={<DefaultLayout><UsaVC /></DefaultLayout>} />
          <Route path="/H1b" element={<DefaultLayout><H1b /></DefaultLayout>} />
          <Route path="/CanadaUniversity" element={<DefaultLayout><CanadaUniversity /></DefaultLayout>} />
          <Route path="/Details/:companyId" element={<DefaultLayout><Details /></DefaultLayout>} />


          <Route path="/businessuserapprove" element={<DefaultLayout><BusinessUserApprove /></DefaultLayout>} />
          <Route path="/businessuseractivate" element={<DefaultLayout><BusinessUserActivate /></DefaultLayout>} />


          <Route path="/bulkemailhome" element={<DefaultLayout><BulkEmailHome /></DefaultLayout>} />
          <Route path="/addmailingemail" element={<DefaultLayout><AddMailingEmail /></DefaultLayout>} />
          <Route path="/addsendingemails" element={<DefaultLayout><AddSendingEmails /></DefaultLayout>} />

          <Route path="/smpmainpage" element={<DefaultLayout><SmpHome /></DefaultLayout>} />

          {/* <Route path="/Healthbook" element={<DefaultLayout><Healthbook /></DefaultLayout>} /> */}


          <Route path="/facebookschedule" element={<DefaultLayout><FacebookSchedule /></DefaultLayout>} />
          <Route path="/uploadmultipleimages" element={<DefaultLayout><UploadMultipleImages /></DefaultLayout>} />          
          <Route path="/viewmultipleimages" element={<DefaultLayout><ViewMultipleImages /></DefaultLayout>} /> 
          <Route path="/schedulemainpage" element={<DefaultLayout><ScheduleMainPage /></DefaultLayout>} /> 

          {/* <Route path="/LeadersAdding" element={<DefaultLayout><LeadersAdding /></DefaultLayout>} />  */}

          <Route path="/txforgotpasswordemail" element={<DefaultLayout>< TxForgotPasswordEmail /></DefaultLayout>} />
          <Route path="/txpasswordresetpage" element={<DefaultLayout>< TxPasswordResetPage /></DefaultLayout>} />

          <Route path="/LeadersAdding" element={<DefaultLayout><Leaders /></DefaultLayout>} /> 

          <Route path="/pagelog" element={<DefaultLayout><PageLogLocation /></DefaultLayout>} /> 
          <Route path="/pagelogdisplay" element={<DefaultLayout><PageLogDisplay/></DefaultLayout>} /> 
          <Route path="/pagedisplaycount" element={<DefaultLayout><PageDisplayCount/></DefaultLayout>} /> 
          
          <Route path="/basicapplogin" element={<BasicAppLogin />} /> 

          <Route path="/bulkemailhidemail" element={<DefaultLayout><BulkEmailHideMail /></DefaultLayout>} />

          <Route path="/Add_hospital_details" element={<DefaultLayout><HosptialAdd /></DefaultLayout>} />

          {/* <Route path="/aboutus_models" element={<DefaultLayout><AboutusMBModels /></DefaultLayout>} />
          <Route path="/aboutus_platform" element={<DefaultLayout><AboutusMBPlatform /></DefaultLayout>} />
          <Route path="/aboutus_ecosystem" element={<DefaultLayout><AboutusMBEcosystem /></DefaultLayout>} /> */}

          <Route path="/portalproblems" element={<DefaultLayout><PortalProbs /></DefaultLayout>} />
          {/* <Route path="/Activeactivitys" element={<DefaultLayout><ActiveActivity /></DefaultLayout>} /> */}
          <Route path="/facebookotp" element={<DefaultLayout><FacebookOtp /></DefaultLayout>} />

          <Route path="/mobilenews" element={<MobileNews />} />
          <Route path="/singlepagesmobile" element={<SinglepagesMobile />} />

          <Route path="/facebookscrapper" element={<DefaultLayout><FacebookContentScrapperHome /></DefaultLayout>} />
          <Route path="/facebookpagepostscrapper" element={<DefaultLayout><FacebookPagePostScrapper /></DefaultLayout>} />
          <Route path="/facebookurlgooglescrapper" element={<DefaultLayout><FacebookUrlGoogleScrapper /></DefaultLayout>} />
          <Route path="/displayanalytics" element={<DefaultLayout><DisplayAnalytics /></DefaultLayout>} />

{/* 
          <Route path="/addsocial" element={<DefaultLayout><SocialMainPage /></DefaultLayout>} />
          <Route path="/addsocialredirect" element={<DefaultLayout><SocialRedirectPage /></DefaultLayout>} /> */}

          <Route path="/trendingposts" element={<DefaultLayout><TrendingPosts /></DefaultLayout>} />

          <Route path="/familygrouphome" element={<DefaultLayout><FamilyGroupHome /></DefaultLayout>} />
          <Route path="/familygroupdetails" element={<DefaultLayout><FamilyGroupDetails /></DefaultLayout>} />


          
          {/* <Route path="/createsubuser" element={<DefaultLayout><CreateSubUser /></DefaultLayout>} /> */}
          <Route path="/healthbookmobile" element={<DefaultLayout><HealthBookMobile /></DefaultLayout>} />
          {/* <Route path="/ratethisapp" element={<DefaultLayout><ratethisapp /></DefaultLayout>} />
          <Route path="/appfeedback" element={<DefaultLayout><appfeedback /></DefaultLayout>} />
          <Route path="/termsandconditions" element={<DefaultLayout><termsandconditions /></DefaultLayout>} />
          <Route path="/privacy" element={<DefaultLayout><privacy /></DefaultLayout>} /> */}     


          <Route exact path='/api/columns' element={<DefaultLayout><HbReleases /></DefaultLayout>} />
          <Route exact path='/resumeupload' element={<DefaultLayout><ResumeUpload /></DefaultLayout>} />

          <Route exact path='/gapanalysis' element={<DefaultLayout><GapAnalysis /></DefaultLayout>} />
          <Route exact path='/roadmap' element={<DefaultLayout><Roadmap/></DefaultLayout>} />
          <Route exact path='/careerbook' element={<DefaultLayout><CareerBook/></DefaultLayout>} />


          <Route exact path='/topvolunteers' element={<DefaultLayout><TopVolunteers/></DefaultLayout>} />     

           <Route path='/trends' element={<DefaultLayout><Trends/></DefaultLayout>}/> 
           <Route path='/fbengagementslist' element={<DefaultLayout><FbEngagementsList/></DefaultLayout>}/> 
           <Route path="/bestPost" element={<DefaultLayout><BestPosts /></DefaultLayout>} />
           <Route path="/trendsfollowercount" element={<DefaultLayout><TrendsShowFollowerCount /></DefaultLayout>} />
           <Route path="/trendshome" element={<DefaultLayout><TrendsHome /></DefaultLayout>} />


           <Route path="/editaccountprofilebusiness" element={<DefaultLayout><EditAccountProfileBusiness /></DefaultLayout>} />

           <Route path="/kfdocmntapproval" element={<DefaultLayout><KfDocmntApproval /></DefaultLayout>} />

           <Route path="/kfdocmntapprovaldetails" element={<DefaultLayout>< KfDocmntApprovalDetails /></DefaultLayout>} />

           {/* cindex/pindex admin panel  */} 
             <Route path="/ciindexpindexmainpage" element={<DefaultLayout>< CiIndexPindexMainPage /></DefaultLayout>} />

             <Route path="/pindex" element={<DefaultLayout><Lab/></DefaultLayout>} />
             <Route path="/cindex" element={<DefaultLayout><CindexPython/></DefaultLayout>} />

             <Route path="/instagram_personal_to_business_tutorial" element={<DefaultLayout><InstagramTutorial/></DefaultLayout>} />

             <Route path="/UploadTest" element={<DefaultLayout><UploadTest/></DefaultLayout>} />

             <Route path="/watermarkadding" element={<DefaultLayout><WaterMarkAddingHome/></DefaultLayout>} />

             <Route path="/watermarkform" element={<DefaultLayout><WaterMarkForm/></DefaultLayout>} />

             

             


           

           








          


        </Routes>

      </Router>
    </>
  );
}

export default App;
